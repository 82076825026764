import { PublicAttendanceList } from "../models/AttendanceListModel";
import Head from "next/head";
import React from "react";
import { Immutable } from "immer";

type PageHeadProps = {
  list?: PublicAttendanceList | Immutable<PublicAttendanceList>;
  noRobots?: boolean;
  title?: string;
  description?: string;
  url?: string;
};

export function PageHead({
  list,
  title,
  description,
  url,
  noRobots = false,
}: PageHeadProps): JSX.Element {
  const pageDescription = list?.description
    ? list.description
    : description
    ? description
    : "Houd al je aanmeldingen overzichtelijk bij op een plek.";
  // TODO: add og:locale og:locale:alternate
  return (
    <Head>
      <title>{formatPageTitle(list, title, true)}</title>
      <meta property="og:title" content={formatPageTitle(list, title, false)} />
      <meta name="description" content={pageDescription} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:type" content="website" />
      {url && <meta property="og:url" content={url} />}
      <meta property="og:image" content="/preview-512.png" />
      {noRobots ? (
        <meta name="robots" content="noindex, nofollow" />
      ) : (
        <meta name="robots" content="index, follow" />
      )}
    </Head>
  );
}

function formatPageTitle(
  list: PageHeadProps["list"],
  title: PageHeadProps["title"],
  withSiteName: boolean
) {
  const siteName = withSiteName ? " - BenIkBij.app" : "";
  return list
    ? `${list.title} van ${list.initiator.displayName}${siteName}`
    : title
    ? `${title}${siteName}`
    : `${
        withSiteName ? "BenIkBij.app - " : ""
      }Aanwezigheid bijhouden was nog nooit zo gemakkelijk`;
}
