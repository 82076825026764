import pino from "pino";
import * as Sentry from "@sentry/nextjs";
import { Severity } from "@sentry/nextjs";

const isDev = process.env.NODE_ENV === "development";
const browserLogger = getBrowserLogger();

export const baseLogger = pino({
  transport: isDev
    ? // enable pino-pretty in development
      {
        target: "pino-pretty",
        options: {
          translateTime: "SYS:standard",
        },
      }
    : undefined,
  browser: {
    write: !isDev
      ? () => {
          /* Do not log to console in production */
        }
      : undefined,
    transmit: {
      level: isDev ? "debug" : "info",
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      send: (level, logEvent) => {
        let message;
        let err;
        for (const msg in logEvent.messages) {
          if (typeof message === "string") {
            message = msg;
          }
          if ((msg as unknown as Record<string, Error>).error) {
            err = (msg as unknown as Record<string, Error>).error;
          }
        }
        if (!err) {
          browserLogger[level](
            message || "NO MESSAGE",
            Object.assign({}, ...logEvent.bindings)
          );
        } else {
          browserLogger[level](
            err,
            Object.assign({ message }, ...logEvent.bindings)
          );
        }
      },
    },
  },
  enabled: process.env.NODE_ENV !== "test",
  level: isDev ? "debug" : "info",
  base: {
    vercelEnv: process.env.NEXT_PUBLIC_VERCEL_ENV,
  },
});

// Do not register these handlers:
// - in the browser
// - during tests
// - During (next.js) build
if (
  typeof window === "undefined" &&
  process.env.NODE_ENV !== "test" &&
  process.env.NEXT_PHASE !== "phase-production-build"
) {
  // Catch all exits and errors
  const handler = (err: Error | null, evt: string) => {
    console.info(
      JSON.stringify({ level: 30, msg: `${evt} caught`, time: Date.now() })
    );
    if (err)
      console.error({
        level: 60,
        msg: "error caused exit",
        time: Date.now(),
        error: err,
      });
  };
  process.on("uncaughtException", (err) => handler(err, "uncaughtException"));
  process.on("unhandledRejection", (err) =>
    handler(err instanceof Error ? err : null, "unhandledRejection")
  );
  process.on("beforeExit", () => handler(null, "beforeExit"));
  process.on("exit", () => handler(null, "exit"));
  process.on("SIGINT", () => handler(null, "SIGINT"));
  process.on("SIGQUIT", () => handler(null, "SIGQUIT"));
  process.on("SIGTERM", () => handler(null, "SIGTERM"));
}

export default function getLogger(moduleName: string): pino.Logger {
  return baseLogger.child({
    module: moduleName,
  });
}
type BrowserLogLevel = "fatal" | "error" | "warn" | "info" | "debug" | "trace";
type BrowserLogFunc = (
  message: string | Error,
  extras?: Record<string, unknown>
) => void;
type BrowserLogger = Record<BrowserLogLevel, BrowserLogFunc>;

export function getBrowserLogger(): BrowserLogger {
  const logLevels: BrowserLogLevel[] = [
    "fatal",
    "error",
    "warn",
    "info",
    "debug",
    "trace",
  ];
  const result: Record<string, unknown> = {};
  for (const level of logLevels) {
    result[level] = function (
      message: string | Error,
      extras?: Record<string, unknown>
    ) {
      if (message instanceof Error) {
        Sentry.captureException(message, {
          extra: extras,
        });
        return;
      }
      Sentry.captureMessage(message, {
        level: Severity.fromString(level),
        extra: extras,
      });
    };
  }
  return result as BrowserLogger;
}
