import Link from "next/link";
import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from "react";
import { BeeIcon } from "./icons";
import { PageErrorBoundary } from "./PageErrorBoundary";

type OnlyChildrenProps = { children: ReactNode };
export function PageContainer({ children }: OnlyChildrenProps): JSX.Element {
  return (
    <div className="space-y-10 min-h-screen flex flex-col items-stretch md:space-y-20">
      {children}
    </div>
  );
}

type StyledLinkProps = Partial<
  Omit<
    DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    "className" | "href"
  >
> & {
  href: string;
  children: ReactNode;
};
function HeaderLink({
  href,
  children,
  ...anchorProps
}: StyledLinkProps): JSX.Element {
  return (
    <Link href={href} passHref>
      <a
        {...anchorProps}
        className="text-base font-medium text-gray-500 transition-colors duration-500 rounded-sm hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-gray-500 sm:text-lg"
      >
        {children}
      </a>
    </Link>
  );
}

export function PageHeader(): JSX.Element {
  return (
    <header className="z-10 max-w-screen-lg mx-auto w-full">
      <div className="px-4 sm:px-6 md:px-10 lg:flex lg:flex-row-reverse lg:justify-between lg:flex-nowrap">
        <nav className="py-4 mb-10 border-b flex items-center justify-between sm:py-6 md:justify-around lg:justify-between lg:w-full lg:pl-20 lg:h-24 lg:pt-10 lg:mb-0">
          <HeaderLink href="/new">Nieuw lijst</HeaderLink>
          <HeaderLink href="/about">Over</HeaderLink>
        </nav>
        <Logo className="lg:flex-shrink-0 lg:border-b lg:h-24 lg:pt-4" />
      </div>
    </header>
  );
}

export function PageContentWrapper({
  children,
}: OnlyChildrenProps): JSX.Element {
  return (
    <main className="px-4 max-w-screen-lg w-full mx-auto space-y-10 flex-grow sm:px-6 md:px-10 md:space-y-14">
      <PageErrorBoundary>{children}</PageErrorBoundary>
    </main>
  );
}

type FeatureCardProps = {
  icon: ReactElement;
  title: string;
  children: ReactNode;
};
export function FeatureCard({
  icon,
  title,
  children,
}: FeatureCardProps): JSX.Element {
  return (
    <section className="sm:text-xl sm:flex sm:items-center">
      {React.cloneElement(icon, {
        className:
          "text-yellow-500 w-10 inline-block mr-4 sm:w-28 sm:mx-6 sm:flex-shrink-0 md:mx-20",
      })}
      <span>
        <h2 className="my-5 font-header font-bold text-yellow-500 text-2xl inline-block align-middle sm:text-4xl">
          {title}
        </h2>
        {children}
      </span>
    </section>
  );
}

export function TextLink({
  href,
  children,
  ...anchorProps
}: StyledLinkProps): JSX.Element {
  return (
    <Link href={href} passHref>
      <a
        {...anchorProps}
        className="border-b-2 border-yellow-500 hover:border-warm-gray-800 transition-colors duration-200 rounded-sm focus:outline-none focus:border-opacity-0 focus:ring-offset-1S focus:ring-2 focus:ring-yellow-500"
      >
        {children}
      </a>
    </Link>
  );
}

function FooterLink({
  href,
  children,
  ...anchorProps
}: StyledLinkProps): JSX.Element {
  return (
    <Link href={href} passHref>
      <a
        {...anchorProps}
        className="border-b-2 border-white border-opacity-0 hover:border-opacity-100 transition-colors duration-200 rounded-sm focus:outline-none focus:border-opacity-0 focus:ring-1 focus:ring-offset-2 focus:ring-white"
      >
        {children}
      </a>
    </Link>
  );
}

export function PageFooter(): JSX.Element {
  const currentYear = new Date().getFullYear();
  const displayYear =
    currentYear === 2021 ? currentYear : `2021-${currentYear}`;
  return (
    <footer className="bg-yellow-500 md:mt-20">
      <div className="px-4 pt-10 pb-4 max-w-screen-lg mx-auto flex flex-wrap items-center justify-around text-base text-white gap-4 sm:text-lg">
        <FooterLink href="/about">Over</FooterLink>
        <FooterLink href="/terms">Voorwaarden</FooterLink>
        <span className="text-xs sm:text-sm">
          &copy; {displayYear} <span className="font-mono">ITies</span> -
          Alle&nbsp;rechten&nbsp;voorbehouden
        </span>
      </div>
    </footer>
  );
}

/**
 * The Icon and text
 * @param props
 * @constructor
 */
export function Logo(
  props: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
): JSX.Element {
  return (
    <span {...props}>
      <BeeIcon className="inline-block align-middle w-10 transform -rotate-45 sm:w-16" />
      <Link href="/">
        <a className="pl-3 inline-block align-middle font-bold font-header text-3xl text-yellow-500 tracking-wide rounded focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-yellow-500 sm:text-5xl">
          BenIkBij
          <span className="font-normal font-header text-lg text-yellow-500 tracking-tight sm:text-3xl">
            .app
          </span>
        </a>
      </Link>
    </span>
  );
}
