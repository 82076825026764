import { ReactNode } from "react";
import { NextComponentType } from "next";
import {
  PageContainer,
  PageContentWrapper,
  PageFooter,
  PageHeader,
} from "../page";
import { PageHead } from "../PageHead";

export type LayoutFunction = (page: JSX.Element) => JSX.Element;
export type LayoutComponent = NextComponentType & {
  getLayout?: LayoutFunction;
};

export type PageLayoutProps = {
  title?: string;
  description?: string;
  children: ReactNode;
};

function PageLayout({
  children,
  title,
  description,
}: PageLayoutProps): JSX.Element {
  return (
    <PageContainer>
      <PageHead title={title} description={description} />
      <PageHeader />
      <PageContentWrapper>{children}</PageContentWrapper>

      <PageFooter />
    </PageContainer>
  );
}

export function getLayout(
  page: JSX.Element,
  title?: string,
  description?: string
): JSX.Element {
  return (
    <PageLayout title={title} description={description}>
      {page}
    </PageLayout>
  );
}

export default PageLayout;
