import { Component, ErrorInfo, ReactNode } from "react";
import getLogger from "../lib/logger";

const logger = getLogger("PageErrorBoundary");

type Props = { children: ReactNode };
type State = { hasError: boolean };

export class PageErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logger.error({ error, errorInfo }, "Error caught by error boundary");
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <>
          <h1 className="font-header font-bold text-2xl">
            Er is iets mis gegaan... :(
          </h1>
          <p>We gaan ermee aan de slag. Progeer het later nog een keer.</p>
        </>
      );
    }
    return this.props.children;
  }
}
