import { SVGProps } from "react";

// Remove props that are already set in this file and should be the same for all icons
type SVGIconProps = Omit<
  SVGProps<SVGSVGElement>,
  "viewBox" | "xmlns" | "stroke" | "fill"
>;

export function BeeIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path
        d="M256 76c-31 0-56 21-56 48h112c0-27-25-48-56-48z"
        fill="#585661"
      />
      {/* NOTE: this below is the yellow part of the bee */}
      <path
        d="M354 316s-6 120-98 120c-96 0-97-120-97-120s20-22 29-40c7-15 6-43 14-60 8-18 34-20 54-20 21 0 40 8 56 22 12 9 1 41 8 56 8 18 34 42 34 42z"
        fill="#f59e0b"
      />
      <g fill="#6b676e">
        <path d="M333 294l-13-20c-3-6-3-15-3-24a381 381 0 01-123 0c-1 9-3 19-6 26l-11 17c18 5 43 10 79 10 35 0 60-4 77-9zM352 328c-17 7-46 16-96 16-49 0-78-9-95-16 2 21 5 36 15 52 17 6 44 10 80 10s64-4 81-10l3-5a181 181 0 0012-47z" />
      </g>
      <path
        d="M324 184c0 29-14 52-68 52s-68-23-68-52 14-52 68-52 68 23 68 52z"
        fill="#585661"
      />
      <path
        d="M326 164c0 22-14 40-70 40s-70-18-70-40 14-40 70-40 70 18 70 40z"
        fill="#6b676e"
      />
      <g fill="#ffebd2">
        <path d="M464 260a718 718 0 00-152-126c18 22 11 40 8 54-16 70 14 114 32 128 39 30 152 67 152 40 0-42-17-75-40-96zM48 260a718 718 0 01152-126c-18 22-11 40-8 54 16 70-14 114-32 128-39 30-152 67-152 40 0-42 17-75 40-96z" />
      </g>
      <path d="M479 265a155 155 0 00-10-11c-32-33-131-111-149-126-1-15-7-28-16-39l30-4-6 29c0 4 1 7 4 9l24 16a8 8 0 0011-3c2-3 1-8-3-11l-19-13 7-34a8 8 0 00-9-10c-2 0-43 5-56 8a64 64 0 00-62 0c-13-3-54-8-56-8a8 8 0 00-9 10l7 34-19 13a8 8 0 108 14l24-16c3-2 4-5 4-9l-6-29 30 4c-9 11-15 24-16 39A2739 2739 0 0033 265c-22 26-33 57-33 91 0 5 2 17 26 17 30 0 91-21 126-42 2 29 12 56 30 77 20 23 46 36 74 36s54-13 74-36c18-21 28-48 30-77 35 21 96 42 126 42 24 0 26-12 26-17 0-34-11-65-33-91zM332 158c30 25 82 67 112 94l-66-16-49-51c2-7 4-16 3-27zm-76-74c23 0 42 16 47 37a222 222 0 00-94 0c5-21 24-37 47-37zm-53 57l2-1c5-4 27-8 51-8a127 127 0 0153 9c11 15 7 29 4 41l-1 4c-4 20-5 39-4 55-8 1-24 3-52 3s-44-2-52-3a176 176 0 00-5-59c-3-12-7-26 4-41zm-1 115c12 3 31 4 54 4s42-1 54-4l8 27c-6 3-24 9-62 9s-56-6-62-9l8-27zm-22-98c-1 11 1 20 3 27l-49 51-66 16c30-27 82-69 112-94zM26 357l-10-1c0-31 10-58 29-81l71-18-11 15c-5 10-16 45-17 71-24 8-48 14-62 14zm129-47c-11 9-30 18-50 27 2-23 11-51 14-57 6-11 40-48 68-76 8 57-16 93-32 106zm101 118c-28 0-53-16-69-41 14 5 36 10 69 10s55-5 69-10a85 85 0 01-69 41zm81-63c-9 4-31 16-81 16s-72-12-81-16l-6-27c13 6 40 16 87 16s74-10 87-16l-6 27zm6-45c-8 4-32 18-87 18a168 168 0 01-88-18c6-6 13-13 18-23 7 3 27 11 70 11s63-8 70-11c5 10 12 17 18 23h-1zm14-10c-16-13-40-49-32-106 28 28 62 65 68 76 3 6 12 34 14 57-20-9-39-18-50-27zm129 47c-14 0-38-6-62-14-1-26-12-61-17-71l-11-15 71 18c19 23 29 50 29 81l-10 1z" />
    </svg>
  );
}

export function LightningBoltIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 10V3L4 14h7v7l9-11h-7z"
      />
    </svg>
  );
}

export function SparklesIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
      />
    </svg>
  );
}

export function GlobeIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

export function ChevronRightIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  );
}

export function ChevronLeftIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 19l-7-7 7-7"
      />
    </svg>
  );
}

export function CheckCircleIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

export function DotsCircleHorizontalIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

export function DocumentDuplicateIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
      />
    </svg>
  );
}

export function ClipBoardCheckIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
      />
    </svg>
  );
}

export function ClipBoardIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
      />
    </svg>
  );
}

export function MailIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
      />
    </svg>
  );
}

export function PencilIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
      />
    </svg>
  );
}

export function CheckIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 13l4 4L19 7"
      />
    </svg>
  );
}

export function XIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
}

export function TrashIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
      />
    </svg>
  );
}

export function ShareIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
      />
    </svg>
  );
}

export function ExclamationIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
      />
    </svg>
  );
}

export function InformationIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

// Business icons from: https://simpleicons.org/
export function WhatsAppIcon(props: SVGIconProps): JSX.Element {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      stroke="none"
      fill="currentColor"
      {...props}
    >
      <path d="M17.47 14.38c-.3-.15-1.76-.87-2.03-.97-.27-.1-.47-.14-.67.15-.2.3-.76.97-.94 1.17-.17.2-.34.22-.64.07s-1.26-.46-2.4-1.47a8.95 8.95 0 01-1.64-2.06c-.18-.3-.02-.46.13-.6.13-.14.3-.35.44-.53.15-.17.2-.3.3-.5.1-.2.05-.36-.03-.51l-.91-2.21c-.24-.58-.49-.5-.67-.51l-.57-.01c-.2 0-.52.07-.8.37-.26.3-1.03 1.02-1.03 2.48s1.06 2.88 1.21 3.08c.15.2 2.1 3.2 5.08 4.48.7.3 1.26.5 1.7.63.7.22 1.35.2 1.86.11.57-.08 1.76-.71 2-1.4.26-.7.26-1.3.18-1.42-.07-.13-.27-.2-.57-.35m-5.42 7.4A9.87 9.87 0 017 20.41l-.36-.22-3.74.99 1-3.65-.23-.38a9.86 9.86 0 01-1.51-5.26 9.9 9.9 0 0116.87-6.98 9.82 9.82 0 012.9 7 9.9 9.9 0 01-9.89 9.88m8.41-18.3A11.81 11.81 0 0012.05 0a11.91 11.91 0 00-10.3 17.84L.05 24l6.31-1.65a11.88 11.88 0 005.68 1.44h.01c6.55 0 11.89-5.33 11.9-11.89a11.82 11.82 0 00-3.49-8.41z" />
    </svg>
  );
}

// export function SignalIcon(props: SVGIconProps): JSX.Element {
//     return (
//         <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="none" fill="currentColor" {...props}>
//             <path d="M12.01 2.6c-5.22 0-9.45 3.9-9.45 8.7 0 3.02 1.22 5.3 3.16 6.78H5.7l.23 3.04v.05a.3.3 0 00.45.23h.01l2.9-1.68c.85.2 1.77.3 2.71.3 5.22 0 9.45-3.9 9.45-8.71 0-4.81-4.23-8.71-9.45-8.71zM10.4.72a13.06 13.06 0 012.72-.05l.06-.62a13.09 13.09 0 00-2.86.06l.08.61zm3.8.1c.91.16 1.78.43 2.6.79l.26-.57-.4-.16C15.9.58 15.1.35 14.3.2l-.11.6zm9.17 10.55c-.02.92-.16 1.8-.4 2.65l.58.18c.27-.9.42-1.85.43-2.83h-.61zM6.75 1.81c.8-.39 1.67-.7 2.58-.9L9.2.31a12.16 12.16 0 00-2.72.95l.26.55zm13.65 1.4a12 12 0 00-2.33-1.67l-.31.54c.8.45 1.56.98 2.22 1.6l.42-.47zm-5.25 18.84c.95-.24 1.85-.59 2.69-1.03l-.32-.53c-.78.41-1.63.74-2.52.97l.15.6zM3.68 4c.63-.63 1.34-1.2 2.13-1.67l-.33-.53c-.7.42-1.35.91-1.95 1.47l-.3.3.44.43zm19.48 11.28l-.57-.23c-.35.83-.8 1.61-1.36 2.33l.5.37c.58-.76 1.06-1.59 1.42-2.47zM20.73 4.43c.6.68 1.12 1.43 1.53 2.23l.55-.27c-.43-.86-.97-1.65-1.62-2.37l-.47.41zm2.55 3l-.57.25c.31.83.52 1.7.6 2.61l.62-.06c-.09-.96-.3-1.9-.65-2.8zM6.38 22.86l-.96.42-.34-1.02-.58.19.44 1.35c.03.08.09.15.16.18l.13.03c.04 0 .08 0 .12-.03l1.29-.56-.25-.56zm3.83-1.2l-.24-.03-.46-.08c-.06-.01-.12 0-.18.02l-1.95.85.27.56 1.85-.8.37.05.21.04.13-.6zm3.73.03a11.97 11.97 0 01-2.72.11l-.02.62a12.78 12.78 0 002.84-.13l-.1-.6zM1.52 7.12c.37-.83.85-1.6 1.42-2.3l-.47-.41c-.6.74-1.11 1.55-1.5 2.42l-.02.02.57.27zM20.53 18.2a11.5 11.5 0 01-2.07 1.73l.34.51c.8-.52 1.54-1.14 2.2-1.83l-.47-.41zM.97 14.55c-.19-.8-.3-1.68-.32-2.67H.03c.03 1.07.15 2 .35 2.84l.59-.17zM.54 7.92c-.3.91-.47 1.86-.51 2.82l.61.03c.05-.93.21-1.82.49-2.66l-.6-.19zm4.2 13.27l-.6-1.82a.3.3 0 00-.1-.15c-.23-.18-.45-.36-.66-.55l-.42.44c.2.19.4.36.63.54l.56 1.72.59-.19zm-2.1-3.3a7.46 7.46 0 01-1.36-2.31l-.58.23.13.32c.33.8.77 1.5 1.34 2.17l.46-.4z"/>
//         </svg>
//     )
// }
//
// export function MessengerIcon(props: SVGIconProps): JSX.Element {
//     return (
//         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="none" fill="currentColor" {...props}>
//             <path d="M.001 11.639C.001 4.949 5.241 0 12.001 0S24 4.95 24 11.639c0 6.689-5.24 11.638-12 11.638-1.21 0-2.38-.16-3.47-.46a.96.96 0 00-.64.05l-2.39 1.05a.96.96 0 01-1.35-.85l-.07-2.14a.97.97 0 00-.32-.68A11.39 11.389 0 01.002 11.64zm8.32-2.19l-3.52 5.6c-.35.53.32 1.139.82.75l3.79-2.87c.26-.2.6-.2.87 0l2.8 2.1c.84.63 2.04.4 2.6-.48l3.52-5.6c.35-.53-.32-1.13-.82-.75l-3.79 2.87c-.25.2-.6.2-.86 0l-2.8-2.1a1.8 1.8 0 00-2.61.48z"/>
//         </svg>
//     )
// }
